// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    // this.outputTarget.textContent = 'Hello, Stimulus!'
  }

  previewAnswerImage(e) {
    const popup = document.getElementById('preview-answer-image')
    console.log({e, popup})
    const data = e.currentTarget.dataset
    console.log({data})

    const html = `<div class=\"absolute inset-4 lg:inset-8 bg-contain bg-center bg-no-repeat\" style=\"background-image: url('${data.url}')\"></div>`
    popup.querySelector('div').insertAdjacentHTML('afterbegin', html)
    popup.style.display = null;
  }

  closePreviewAnswerImage(e) {
    console.log('close')
    const popup = document.getElementById('preview-answer-image')
    popup.querySelector('div').innerHTML = null;
    popup.style.display = 'none';
  }
}
